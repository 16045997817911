export const environment = {
	deepLinkURLBase: 'https://vivascore.alula.health',
	appEnvironmentBaseUrl:'https://api.alulahealth.com/health/environment',
	
	privacyPolicy:'https://www.alulatechnologies.com/privacy-policy',
	termsOfUse: 'https://www.alulatechnologies.com/terms-of-use',
	dislaimerUrl:'https://www.alulatechnologies.com/VivaScore_Disclaimer',
	
	welcomeBackground: '/assets/v2/welcome/background.png',
	oneSignalAppId: 'fc358462-7175-4b89-9bf5-13224240e909',
	environmentName: 'production',
	production: true,	
	defaultLanguage: 'en',

	countryCode: undefined,
	organizationId: undefined,
	scanFriendAllowed: true,
	enableMobileRegistation: true,
	enableWebRegistation: true,
	
	enableLocalAccounts: true,
	enableExternalLogin: true,

	iosAuthRedirect: 'msauth.com.alulahealth.vitalscore://auth',
	androidAuthRedirect: 'msauth://com.alulatechnologies.vitalscore/2jmj7l5rSw0yVb%2FvlWAYkK%2FYBwk%3D'
	
};
